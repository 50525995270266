import React from 'react'

import Layout from '../components/layout'
import Meta from '../components/meta'
import {Link} from 'gatsby'

const WaitingListSuccessPage = () => (
  <>
    <Meta
      title="Email Was Added Successfully"
    />
    <Layout>
      <div className="typography-page limiter short-page">
        <h2>
          Your email was added successfully!
        </h2>
        <p>
          You'll receive an email notification when ScreenTray for Windows is launched. <br/><br/>
          <Link to="/">Go to the Homepage?</Link>
        </p>
      </div>
    </Layout>
  </>
)

export default WaitingListSuccessPage
